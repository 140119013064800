.custom-yes-no-btns {
  .btn-primary {
    background-color: #fff;
    color: #212121;
    border: solid 1px #c8c8c8;
    border: solid 1px #c8c8c8;
    padding: 10px;
    font-weight: 400;
    width: 90px;
    height: 47px;
    border-radius: 10px;
  }

  .btn-primary:hover {
    cursor: pointer;
    color: #fff ! important;
  }

  .btnradioactive {
    background-color: #007ac9 ! important;
    ;
    color: #fff ! important;
    border-color: #007ac9 ! important;
    ;
  }
}


.custom-group-btns {
  display: flex;

  .btn-primary {
    background-color: #fff;
    color: #212121;
    border: solid 1px #c8c8c8;
    font-weight: 400;
    border-radius: 10px;
    min-height: 47px;
    margin: 0px 10px 10px 0px;
    width: auto;
    padding: 10px 15px 10px 15px;
    width: 95%;

    input[type=radio] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }

  .btn-primary:hover {
    cursor: pointer;
    color: #fff ! important;
  }

  .btnradioactive {
    background-color: #007ac9;
    color: #fff ! important;
    border-color: #007ac9;
  }

  @media only screen and (max-width: 960px) {
    margin-right: -11px;
  }

}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus {
  z-index: 0;
}


.custom-check-box {

  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 34px;
    width: 34px;
    border-radius: 3px;
    box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #c8c8c8;
    background-color: #ffffff;
  }

  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 34px;
    height: 34px;
    content: "";
    background: no-repeat 50%/50% 50%;
  }

  .label-text {
    margin-left: 20px;
    font-size: 15px;
    font-family: "Whitney-Light"
  }

  .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("/assets/svgs/checkmark.svg");
  }

  .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("/assets/svgs/checkmark.svg");
  }


  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #c8c8c8;
    background-color: #fff;
  }

  .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #c8c8c8;
    background-color: #fff;
  }
}

select {
  background: url('/assets/svgs/select-arrow.svg') no-repeat right;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  background-position-x: 95%;
}

select::-ms-expand {
  display: none;
}

@import '../src/styles/main.scss';
@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";

body {
  font-family: 'Whitney-Book';
}


.es-btn {
  border: solid 1px #ff6319;
  height: 53px;
  border-radius: 4px;
  width: 232px;
  max-width: 100%;

  @media only screen and (max-width: 960px) {
    width: 100%;
  }

}

.next-btn {
  @extend .es-btn;
  @extend .btn;
  background-color: #ff6319;
  color: #fff;
  float: right;

  @media only screen and (max-width: 960px) {
    float: none;
  }
}

.back-btn {
  @extend .es-btn;
  @extend .btn;
  background-color: #ffffff;
  color: #ff6319;

  @media only screen and (max-width: 960px) {
    margin-top: 10px;
  }
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}


.back-btn:hover {
  opacity: 0.8;

}

.back-btn:hover {
  color: #ff6319;
}

.next-btn:hover {
  color: #fff;
  background: linear-gradient(to bottom, #fd7741 50%, #ff6319 50%);
}

.btn {
  box-shadow: none !important;
  outline: none !important;
}

.button:active,
button:active,
.button:focus,
button:focus,
.button:hover,
button:hover {
  outline: none !important;
}


.privacy-text {
  font-size: 12px;
  text-align: justify;

  a {
    color: #007ac9 !important;
    cursor: pointer !important;
  }

  a:hover {
    text-decoration: underline !important;
  }
}


.help-quote {
  border-radius: 6px;
  border: solid 2px #e7eef4;
  margin-top: 20px;
  padding: 10px 0px 10px 0px;
  margin: -5px 0px 0px;

  h6 {
    font-family: Whitney-Medium;
    font-size: 16px;
    font-weight: 600;
    color: #007ac9;
    padding-top: 3px;
  }

  .mobile-text-align {
    @media only screen and (max-width: 960px) {
      text-align: center;
    }
  }

  @media only screen and (max-width: 960px) {

    .col-1,
    .col-10 {
      padding-right: 0px;
      padding-left: 5px;
    }

  }
}

.help-quote:hover {
  cursor: pointer;
}


.help-area {
  padding: 30px;

  @media only screen and (max-width: 960px) {
    padding: 10px 20px 10px 20px;
  }
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

::placeholder {
  /* Recent browsers */
  text-transform: none;
}

input.ng-invalid.ng-touched {
  border: 1px solid #ff6319 ! important;
  box-shadow: none !important;
}

html {
  scroll-behavior: smooth;
}

select.form-control {
  padding: 6px 31px 6px 12px;
}

a:focus {
  outline: none;
}

.ng-select .ng-select-container .ng-value-container .ng-input>input {

  height: 30px;
}

.ng-select.ng-select-single .ng-select-container {
  height: 45px;
  border-radius: 4px;
  box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #c8c8c8;
  background-color: #ffffff;
}


.ng-select .ng-arrow-wrapper .ng-arrow {
  height: 13px !important;
  width: 13px !important;
  position: relative;
  background: url(/assets/svgs/select-arrow.svg) no-repeat right;
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border: none !important;
}

.thank-you-content {
  border-radius: 5px;
  border: solid 2px #e7eef4;
  background-color: #f6fbff;
  padding: 15px 15px 0px 15px;
  margin-bottom: 20px;
  min-height: 275px;

  p {
    font-family: "Whitney-Light";
    font-size: 16px;
    margin-bottom: 15px;
    color: #000000;
  }
}

.mb-15 {
  margin-bottom: 15px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  white-space: normal !important;

}

.marital-status-label {
  margin-top: -95px;

  @media only screen and (max-width: 960px) {
    margin-top: 0px;
  }
}

::ng-deep .modal-content {
  .modal-body {
    max-height: calc(100vh - 80px);
    overflow-y: auto;
  }
}

.quote-page {


  .app-container {
    // global css for  left right rail content across all pages
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;


    .left-container {
      padding: 10px 30px 10px 10px;
      max-width: 750px;
      width: 100%;

    }

    .right-container {
      padding: 10px 10px 10px 35px;
      max-width: 365px;
      width: 100%;
    }

    @media only screen and (max-width: 981px) {
      .left-container {
        padding: 0px;
      }

      .right-container {
        max-width: 630px;
        padding: 20px;
      }
    }

  }


}



.es-form-header {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-image: linear-gradient(to bottom, #0d5a8b, #126ea9);
  position: relative;
  width: 100%;
  margin-bottom: 45px;

  .es-form-header-bg-icon {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 5px;

    img {
      max-width: 100%;
    }

  }

  .es-form-header-icon {
    width: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    top: 60px;

    img {
      max-width: 100%;
    }

    @media (max-width: 1075px) {
      top: 100px;
    }
  }

  @media (max-width: 1075px) {
    border-radius: 0px;
    margin-top: -40px;
    padding-top: 45px;
  }

}



.es-form-container {
  border-radius: 6px;
  border: solid 2px #e3e3e3;

  .es-form-body {
    padding: 20px 63px 32px 31px;

    .es-form-body-icon {
      text-align: center;
      margin-top: -100px;

      @media only screen and (max-width: 960px) {
        margin-top: -70px;

        img {
          height: 120px;
          width: 120px;
        }
      }
    }

    .es-form-title {
      font-family: Whitney-Medium;
      font-size: 22px;
      font-weight: 600;
      color: #ff6319;
      border-bottom: solid 1px #c5c5c5;
      padding: 10px 0px 10px 0px;

      p {
        color: #000000;
        font-size: 18px;
        margin: 5px;
      }
    }

    label {
      font-family: Whitney-Medium;
      color: #000000;
    }

    .es-form-inputs-area {
      padding-top: 30px;

      .form-group {
        margin-bottom: 1.5rem;
      }

      input[type='text'],
      input[type='email'],
      input[type='tel'],
      input[type='password'],
      select {
        height: 45px;
        border-radius: 4px;
        box-shadow: inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #c8c8c8;
        background-color: #ffffff;
      }

      input[type='text']:disabled,
      input[type='email']:disabled,
      input[type='tel']:disabled,
      input[type='password']:disabled,
      select:disabled {
        background-color: #e9ecef;
      }

      .form-input-left {
        width: 30%;
        padding-right: 10px;
        justify-content: left;

        @media only screen and (max-width: 960px) {
          width: 100%;
          margin-bottom: 5px;
        }
      }

      .form-input-right {
        width: 70%;

        @media only screen and (max-width: 960px) {
          width: 100%;
        }
      }

      .form-input-withhelp {
        width: 60%;

        @media only screen and (max-width: 960px) {
          width: 82%;
        }
      }

      .help-display {
        display: flex;
      }

      .form-half-input {
        width: 35%;

        @media only screen and (max-width: 960px) {
          width: 50%;
        }
      }

      @media only screen and (max-width: 960px) {
        padding-top: 15px;
      }

      .input-invalid-text {
        color: #ff6319;
        font-size: 15px;
        margin-top: 6px;
        margin-left: 30%;

        @media only screen and (max-width: 981px) {
          margin-left: 0%;
        }
      }
    }

  }

  @media only screen and (max-width: 960px) {
    border: none;

    .form-inline {
      display: block;
    }

    .es-form-body {
      padding: 10px 20px;
    }
  }
}


.es-form-footer {
  padding: 20px 0px 20px 0px;

  @media only screen and (max-width: 960px) {
    padding: 0px 20px;
  }

}

@media only screen and (max-width: 1075px) {

  .flex-md-row,
  .flex-sm-row {
    .next-btn {
      margin-top: 8px;
    }
  }
}

@font-face {
  font-family: "Whitney-Book";
  src: url("../assets/fonts/Whitney-Book-Bas.woff?#iefix");
}

@font-face {
  font-family: "Whitney-Medium";
  src: url("../assets/fonts/Whitney-Medium-Bas.woff?#iefix");

}

@font-face {
  font-family: "Whitney-Semibold";
  src: url("../assets/fonts/Whitney-Semibold-Bas.woff?#iefix");

}

@font-face {
  font-family: "Whitney-Light";
  src: url("../assets/fonts/Whitney-Light-Bas.woff");

}
